type UserData = {
  password: string;
  oldPassword: string;
} & User;

import { API_ENDPOINT } from '.';
import { getUserFromRaw, User } from '../utils/user';

class UserError extends Error {}

/**
 * Calls the API to receive the user that is currently signed in
 */
export const getUser = (): Promise<[User | undefined, UserError | undefined]> => {
  return new Promise(resolve => {
    fetch(`${API_ENDPOINT}/api/user`, {
      headers: {
        'content-type': 'application/json'
      },
      credentials: 'include',
      method: 'GET'
    })
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json();
          case 401:
            return resolve([
              undefined,
              new UserError(
                'Sie sind nicht autorisiert diese Seite zu bearbeiten. Bitte loggen Sie sich ein.'
              )
            ]);
          default:
            return resolve([undefined, new UserError('Es ist etwas schief gelaufen.')]);
        }
      })
      .then((rawUser: unknown) => {
        try {
          const user = getUserFromRaw(rawUser);
          resolve([user, undefined]);
        } catch (error) {
          if (error instanceof Object && 'message' in error && typeof error.message === 'string') {
            resolve([undefined, new UserError(error.message)]);
          } else if (typeof error === 'string') {
            resolve([undefined, new UserError(error)]);
          } else {
            resolve([undefined, new UserError('Es ist etwas schief gelaufen.')]);
          }
        }
      })
      .catch(e => {
        console.error(e); // we don't wanna swallow the error
        resolve([
          undefined,
          new UserError(
            'Es ist etwas schief gelaufen. Bitte überprüfen Sie Ihre Internetverbindung oder probieren Sie es später noch einmal.'
          )
        ]);
      });
  });
};

/**
 * Updates a user profile by calling the API
 * @param {UserData} user
 * @return {Promise<[any, UserError]>}
 */
export const patchUser = ({
  username,
  password,
  oldPassword,
  department,
  email,
  avatar
}: UserData) => {
  return new Promise(resolve => {
    fetch(`${API_ENDPOINT}/api/user`, {
      headers: {
        'content-type': 'application/json'
      },
      credentials: 'include',
      method: 'PATCH',
      body: JSON.stringify({
        username,
        password,
        oldPassword,
        department,
        email,
        avatar
      })
    })
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json();
          case 401:
            return resolve([
              undefined,
              new UserError(
                'Sie sind nicht autorisiert diese Seite zu bearbeiten. Bitte loggen Sie sich ein.'
              )
            ]);
          case 409:
            return resolve([undefined, new UserError('Ihr altes Passwort ist nicht korrekt.')]);
          default:
            return resolve([undefined, new UserError('Es ist etwas schief gelaufen.')]);
        }
      })
      .then(user => {
        resolve([user, undefined]);
      })
      .catch(e => {
        console.error(e);
        resolve([undefined, new UserError('Es ist etwas schief gelaufen.')]);
      });
  });
};

type LoginData = {
  username: string;
  password: string;
  params: {
    action: string;
    slug?: string;
    commentSlug?: string;
  };
};

type LoginFunction = {
  (data: LoginData): Promise<[User | null, UserError | undefined]>;
};
/**
 * Attempts to log a user in
 */
export const login: LoginFunction = ({ username, password, params }) => {
  return new Promise(resolve => {
    fetch(`${API_ENDPOINT}/api/login`, {
      headers: {
        'content-type': 'application/json'
      },
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify({
        username: username,
        password: password,
        postSlug: params.action === 'claim' ? undefined : params.slug,
        commentSlug: params.commentSlug,
        action: params.action
      })
    })
      .then(response => {
        if (response.status !== 200) {
          return resolve([null, new UserError('Ungültiger Benutzername oder Passwort')]);
        } else {
          return response.json();
        }
      })
      .then((rawUser: unknown) => {
        const user = getUserFromRaw(rawUser);
        return resolve([user, undefined]);
      })
      .catch(e => {
        console.error(e);
        return resolve([
          null,
          new UserError(
            'Es ist etwas schief gelaufen. Bitte überprüfen Sie Ihre Internetverbindung oder probieren Sie es später noch einmal.'
          )
        ]);
      });
  });
};
/**
 * likes a post by its slug
 * @returns {Promise<[any, UserError]}
 */
export const validateUser = ({ token }: { token: string }) => {
  return new Promise(resolve => {
    fetch(`${API_ENDPOINT}/api/user/validate/${token}`, {
      headers: {
        'content-type': 'application/json'
      },
      credentials: 'include',
      method: 'GET'
    }).then(response => {
      switch (response.status) {
        case 201:
          return resolve([response.json(), undefined]);
        default:
          resolve([
            undefined,
            new UserError(
              'Es ist etwas schief gelaufen. Bitte überprüfen Sie Ihre Internetverbindung oder probieren Sie es später noch einmal.'
            )
          ]);
      }
    });
  });
};
