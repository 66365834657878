//@ts-ignore
import React, { useEffect, useState } from 'react';
import { API_ENDPOINT, getUser } from '../api';
import { UserRole } from '../utils/user';

type User = {
  username: string;
  email: string;
  roles: UserRole[];
};

type UserContextType = {
  loading: boolean;
  loggedIn: boolean;
  error: string | null;
  // pure userdata
  username: string | null;
  department: string | null;
  avatar: string | null;
  roles: UserRole[];
  email: string | null;
  loginOpen: boolean;
  login(user: User): void;
  logout(): void;
  openLogin(params?: { action: string; commentSlug?: string; slug?: string }): void;
  closeLogin(): void;
  afterLogin(callback: Function): void;
  action: string;
  callback?: Function;
  setUser(user: User): void;
  params?: { action: string; commentSlug?: string; slug?: string };
};

const defaultState: UserContextType = {
  loading: true,
  loggedIn: false,
  error: null,
  // pure userdata
  username: null,
  department: null,
  avatar: null,
  roles: [] as UserRole[],
  email: null,
  loginOpen: false,
  login(_user: User) {},
  logout() {},
  openLogin: (_params: any) => {},
  closeLogin: () => {},
  afterLogin: (_callback: Function) => {},
  action: 'default',
  callback: undefined,
  setUser() {}
};

export const UserContext = React.createContext(defaultState);
export const useUser = () => React.useContext(UserContext);

export function UserProvider(props: any) {
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    getUser()
      .then(([user, err]) => {
        if (err) {
          return setState({ ...state, loggedIn: false, error: err.message, loading: false });
        }
        setState({ ...state, loggedIn: true, error: null, loading: false, ...user });
      })
      .catch(e => {
        console.error(e);
        setState({
          ...state,
          loggedIn: false,
          error: 'Something unexpected happened!',
          loading: false
        });
      });
  }, []);

  const login = (user: User) => {
    setState(prevState => {
      const updatedState = { ...prevState, loggedIn: true, error: null, loading: false, ...user };

      if (prevState.callback) {
        prevState.callback();
        updatedState.callback = undefined;
      }

      return updatedState;
    });
  };

  const logout = () => {
    const { setUser, ...rest } = defaultState;
    setState({
      ...rest,
      loading: false,
      loggedIn: false,
      setUser() {}
    });
    return fetch(`${API_ENDPOINT}/logout`, {
      credentials: 'include',
      method: 'GET'
    }).then(() => console.log('logged out'));
  };

  const openLogin = (params?: { action: string; commentSlug?: string; slug?: string }) => {
    setState(state => ({
      ...state,
      loginOpen: true,
      action: params && params.action ? params.action : 'default',
      params
    }));
  };

  const closeLogin = () => {
    setState(prevState => ({ ...prevState, loginOpen: false }));
  };

  const afterLogin = (callback: Function) => {
    if (callback) {
      setState(state => ({ ...state, callback }));
    }
  };

  const { children } = props as { children: React.ReactNode };

  return (
    <UserContext.Provider
      value={{
        ...state,
        login,
        logout,
        openLogin,
        closeLogin,
        afterLogin
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
