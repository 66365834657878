import { Link } from 'gatsby';
import React from 'react';
import * as style from './cookie-layer.module.scss';

export class CookieLayer extends React.Component {
  state = { visible: false };

  componentDidMount() {
    if (this.props.initialVisible) {
      this.setState({ visible: true });
    }
  }

  handleSubmit = () => {
    this.props.onAccept();
    this.setState({ visible: false });
  };

  render() {
    return this.state.visible ? (
      <div className={style.cookieLayer}>
        <p>
          Wir verwenden Cookies, um Ihnen den bestmöglichen Service zu gewährleisten, und Ihnen zu
          ermöglichen, zu einem späteren Zeitpunkt Ihre Beiträge wiederzufinden. Wenn Sie auf der
          Seite weitersurfen stimmen Sie der Cookie-Nutzung zu.
          <br />
          Detaillierte Informationen über den Einsatz von Cookies auf dieser Webseite finden Sie
          unter <Link to="/imprint">Datenschutzerklärung</Link>
        </p>
        <button onClick={this.handleSubmit}>Verstanden!</button>
      </div>
    ) : null;
  }
}
