export enum UserRole {
  Admin = 'ROLE_ADMIN',
  User = 'ROLE_USER',
  Editor = 'ROLE_EDITOR',
  Legitimation = 'ROLE_LEGITIMATION'
}

export type User = {
  username: string;
  email?: string;
  avatar: string | null;
  department: string | null;
  roles: UserRole[];
};

export const getUserFromRaw = (rawUser: unknown) => {
  let user: User;
  let roles: UserRole[] = [];
  let avatar: string | null = null;
  let department: string | null = null;

  if (!(rawUser instanceof Object)) {
    console.error('rawUser is not an object');
    throw new Error('rawUser is not an object');
  }

  if (!('username' in rawUser) || typeof rawUser.username !== 'string') {
    console.error('username not in rawUser');
    throw new Error('username not in rawUser');
  }

  if (!('email' in rawUser) || typeof rawUser.email !== 'string') {
    console.error('email not in rawUser');
    throw new Error('email not in rawUser');
  }

  if ('avatar' in rawUser && typeof rawUser.avatar === 'string') {
    avatar = rawUser.avatar;
  }

  if ('department' in rawUser && typeof rawUser.department === 'string') {
    department = rawUser.department;
  }

  if ('roles' in rawUser && Array.isArray(rawUser.roles)) {
    roles = rawUser.roles
      .map(role => {
        switch (role) {
          case 'ROLE_ADMIN':
            return UserRole.Admin;
          case 'ROLE_USER':
            return UserRole.User;
          case 'ROLE_EDITOR':
            return UserRole.Editor;
          case 'ROLE_LEGITIMATION':
            return UserRole.Legitimation;
          default:
            console.error(`Unknown role: ${role}`);
            return (null as any) as UserRole;
        }
      })
      .filter(role => role);
  }

  user = {
    username: rawUser.username,
    email: rawUser.email,
    avatar,
    department,
    roles
  };

  return user;
};
